<!--我的项目-需要排期的需求列表页-->
<template>
  <!-- <el-card>
      <el-row :gutter="20">
        <el-col :span="6"
                v-for="count in demand_Statistics"
                :key="count.id">
          <div>

            <el-statistic group-separator=","
                          :precision="0"
                          :value="count.value"
                          :title="count.title">
              <template slot="prefix">
                <i class="el-icon-star-on"
                   style="color: red"></i>
              </template>

            </el-statistic>
          </div>
        </el-col>
      </el-row>
    </el-card> -->

  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-tabs v-model="tabName" @tab-click="handleClick">
        <el-tab-pane label="进行中" name="first">
          <search-component
            :key="timer"
            :itemList="searchList"
            :formData="searchData"
          >
          </search-component>

          <div class="table_box">
            <el-table
              :data="demandList"
              border
              style="width: 96%"
              height="640"
              align="center"
              :row-class-name="tableRowClassName"
            >
              <el-table-column label="序号" width="80px">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span>{{ scope.$index + 1 }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="需求编号" width="120px">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium">{{ scope.row.id }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="需求名称" width="">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium">{{ scope.row.demandName }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="原型地址">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <a :href="scope.row.demandUrl" target="_blank"
                      ><el-tag size="medium">{{
                        scope.row.demandUrl
                      }}</el-tag></a
                    >
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="需求状态" width="150px">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium">{{ scope.row.demandStatusStr }}</span>
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column label="所属系统"
                               width="150px">
                <template slot-scope="scope">
                  <div slot="reference"
                       class="name-wrapper">
                    <span size="medium">{{ scope.row.systemName }}</span>
                  </div>
                </template>
              </el-table-column> -->

              <el-table-column label="参与人员">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium">{{ scope.row.participationName }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="发起时间" width="160px">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium">{{ scope.row.createTime }}</span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button size="mini" @click="demandDetail(scope.row.id)"
                    >查看</el-button
                  >
                  <!-- <el-button size="mini"
             @click="editSchedule(scope.row.id)">编辑排期</el-button> -->
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已完成" name="second">
          <search-component
            :key="timer"
            :itemList="searchListHistory"
            :formData="searchData"
          >
          </search-component>

          <el-table
            :data="demandListHistory"
            border
            style="width: 96%"
            height="640"
            align="center"
            :row-class-name="tableRowClassName"
          >
            <el-table-column label="序号" width="80px">
              <template slot-scope="scope">
                <div slot="reference" class="name-wrapper">
                  <span>{{ scope.$index + 1 }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="需求编号" width="80px">
              <template slot-scope="scope">
                <div slot="reference" class="name-wrapper">
                  <span size="medium">{{ scope.row.id }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="需求名称" width="">
              <template slot-scope="scope">
                <div slot="reference" class="name-wrapper">
                  <span size="medium">{{ scope.row.demandName }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="原型地址" width="120">
              <template slot-scope="scope">
                <div slot="reference" class="name-wrapper">
                  <a :href="scope.row.demandUrl" target="_blank"
                    ><el-tag size="medium">{{ scope.row.demandUrl }}</el-tag></a
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column label="需求状态" width="100px">
              <template slot-scope="scope">
                <div slot="reference" class="name-wrapper">
                  <div v-if="scope.row.demandStatus === '08'">
                    <el-tag type="success">{{
                      scope.row.demandStatusStr
                    }}</el-tag>
                  </div>
                  <div v-else>
                    <el-tag type="danger">{{
                      scope.row.demandStatusStr
                    }}</el-tag>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="所属系统"
                             width="100px">
              <template slot-scope="scope">
                <div slot="reference"
                     class="name-wrapper">
                  <span size="medium">{{ scope.row.systemName }}</span>
                </div>
              </template>
            </el-table-column> -->
            <el-table-column label="参与人员">
              <template slot-scope="scope">
                <div slot="reference" class="name-wrapper">
                  <span size="medium">{{ scope.row.participationName }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="发起时间" width="160px">
              <template slot-scope="scope">
                <div slot="reference" class="name-wrapper">
                  <span size="medium">{{ scope.row.createTime }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="提测日期" width="160px">
              <template slot-scope="scope">
                <div slot="reference" class="name-wrapper">
                  <span size="medium">{{ scope.row.testDate }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="上线日期" width="160px">
              <template slot-scope="scope">
                <div slot="reference" class="name-wrapper">
                  <span size="medium">{{ scope.row.onlineDate }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="工时" width="100px">
              <template slot-scope="scope">
                <div slot="reference" class="name-wrapper">
                  <span size="medium">{{ scope.row.workHours + '天/人' }}</span>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="操作" width="80">
              <template slot-scope="scope">
                <el-button size="mini" @click="demandDetail(scope.row.id)"
                  >查看</el-button
                >
                <!-- <el-button size="mini"
             @click="editSchedule(scope.row.id)">编辑排期</el-button> -->
              </template>
            </el-table-column>
          </el-table></el-tab-pane
        >
      </el-tabs>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageQuery.current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageQuery.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import SearchComponent from '../../common/Serach.vue';
export default {
  components: {
    SearchComponent,
  },
  data() {
    return {
      // 当前活动页面路径
      atactivePath: '',
      // 定义当前活动导航的名称
      activeName: '',
      // 定义当前活动导航父级导航名称
      parentName: '',
      tabName: 'first',
      demand_Statistics: [
        {
          value: 36,
          title: '需求总数',
        },
        {
          value: 4154.564,
          title: '所用工时',
        },
        {
          value: 34,
          title: '已完成需求',
        },
        {
          value: 2,
          title: '进行中需求',
        },
      ],
      demandList: [],
      demandListHistory: [],
      weekFirst: '',
      weekEnd: '',
      dialogTableVisible: false,
      dialogFormVisible: false,
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
      pageQuery: {
        version: '',
        demandName: '',
        status: [],
        current: 1,
        size: 10,
        username: '',
        nickname: '',
      },
      searchData: {
        version: '',
        demandName: '',
        status: [],
        current: 1,
        size: 10,
        username: '',
        nickname: '',
      },
      total: 0,
      formLabelWidth: '120px',
      searchList: [
        {
          label: '版本号：',
          prop: 'demandId',
          type: 'input',
          change: this.getEvent,
        },
        {
          label: '需求名称：',
          prop: 'demandName',
          type: 'input',
          change: this.getEvent,
        },
        {
          label: '需求状态：',
          prop: 'status',
          type: 'select',
          multiple: true,
          options: [
            // bug类型
            {
              label: '待CR',
              value: '02',
            },
            {
              label: '待提测',
              value: '03',
            },
            {
              label: '提测待通过',
              value: '04',
            },
            {
              label: '待测试',
              value: '05',
            },
            {
              label: '测试中',
              value: '06',
            },
            {
              label: '待上线',
              value: '07',
            },
            // ,{
            //   label: '已上线',
            //   value: '08'
            // }
          ],
        },
        // { label: '时间：', prop: 'date', type: 'daterange', valueFormat: 'yyyy-MM-dd' },
        {
          type: 'button',
          btnList: [
            {
              type: 'primary',
              label: '查询',
              size: 'mini',
              icon: 'el-icon-search',
              handle: (row) => this.search(),
            },
            {
              type: 'info',
              label: '重置',
              size: 'mini',
              icon: 'el-icon-setting',
              handle: (row) => this.reset(),
            },
          ],
        },
      ],
      searchListHistory: [
        {
          label: '版本号：',
          prop: 'version',
          type: 'input',
          change: this.getEvent,
        },
        {
          label: '需求名称：',
          prop: 'demandName',
          type: 'input',
          change: this.getEvent,
        },

        // { label: '时间：', prop: 'date', type: 'daterange', valueFormat: 'yyyy-MM-dd' },
        {
          type: 'button',
          btnList: [
            {
              type: 'primary',
              label: '查询',
              size: 'mini',
              icon: 'el-icon-search',
              handle: (row) => this.search(),
            },
            {
              type: 'info',
              label: '重置',
              size: 'mini',
              icon: 'el-icon-setting',
              handle: (row) => this.reset(),
            },
          ],
        },
      ],

      timer: '',
    };
  },

  created() {
    this.activePath = window.sessionStorage.getItem('activePath');
    this.activeName = window.sessionStorage.getItem('activeName');
    this.parentName = window.sessionStorage.getItem('parentName');
    this.getMyPageList();
  },

  methods: {
    tableRowClassName({ row }) {
      if (row.is_Schedule === true) {
        return 'warning-row';
      } else if (row.is_Schedule === false) {
        return 'success-row';
      }
      return '';
    },
    // editSchedule (row) {
    //   window.sessionStorage.setItem('tabName', 'second')
    //   this.$router.push({
    //     path: '/demandDetail',
    //     query: {
    //       demandId: row
    //     }
    //   }
    //   )
    // },
    async getMyPageList() {
      if (this.searchData.status.length === 0) {
        this.pageQuery.status = ['02', '03', '04', '05', '06', '07'];
        this.pageQuery.demandName = this.searchData.demandName;
        this.pageQuery.version = this.searchData.version;
        const { data: res } = await this.$http.post(
          'py/server/project/getMyPageList',
          this.pageQuery
        );
        if (!res.success) return this.$message.error(res.msg);
        this.demandList = res.data.records;
        this.total = res.data.total;
      } else {
        const { data: res } = await this.$http.post(
          'py/server/project/getMyPageList',
          this.searchData
        );

        if (!res.success) return this.$message.error(res.msg);
        this.demandList = res.data.records;

        this.total = res.data.total;
      }
    },
    async getMyPageListHistory() {
      if (this.searchData.version === '' && this.searchData.demandName === '') {
        this.pageQuery.status = ['08', '09'];
        const { data: res } = await this.$http.post(
          'py/server/project/getMyPageList',
          this.pageQuery
        );
        if (!res.success) return this.$message.error(res.msg);
        this.demandListHistory = res.data.records;
        this.total = res.data.total;
      } else {
        this.searchData.status = ['08'];
        const { data: res } = await this.$http.post(
          'py/server/project/getMyPageList',
          this.searchData
        );
        if (!res.success) return this.$message.error(res.msg);
        this.demandListHistory = res.data.records;
        this.total = res.data.total;
      }
    },
    handleSizeChange(newPage) {
      this.pageQuery.size = newPage;
      this.getMyPageList();
    },
    handleCurrentChange(newCurrent) {
      this.pageQuery.current = newCurrent;
      this.getMyPageList();
    },
    demandDetail(id) {
      window.sessionStorage.setItem('tabName', 'first');
      window.sessionStorage.setItem('demandId', id);
      this.$router.push('/demandDetail');
    },
    getEvent(res) {},
    search() {
      if (this.tabName === 'second') {
        this.getMyPageListHistory();
      }
      if (this.tabName === 'first') {
        this.getMyPageList();
      }
    },
    reset() {
      this.searchData = {
        version: '',
        demandName: '',
        status: [],
        current: 1,
        size: 10,
        username: '',
        nickname: '',
      };
      if (this.tabName === 'first') {
        this.timer = new Date().getTime();
        this.getMyPageList();
      }
      if (this.tabName === 'second') {
        this.timer = new Date().getTime();
        this.getMyPageListHistory();
      }
    },
    handleClick(tab) {
      this.tabName = tab.name;
      this.pageQuery = {
        version: '',
        demandName: '',
        status: [],
        current: 1,
        size: 10,
        username: '',
        nickname: '',
      };
      this.reset();
    },
  },
};
</script>

<style>
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.button_box_left {
  position: absolute;
  top: 50%;
}
.button_box_right {
  position: absolute;
  top: 50%;
  right: 5%;
}

.add_button {
  margin-left: 1385px;
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
