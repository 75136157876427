import { render, staticRenderFns } from "./JiraBug.vue?vue&type=template&id=8ce5aa40&scoped=true&"
import script from "./JiraBug.vue?vue&type=script&lang=js&"
export * from "./JiraBug.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ce5aa40",
  null
  
)

export default component.exports