<template>
    <div>
        <p :style="{ color: 'red' }">*注意：记得部署对应环境的 payment-cron 服务，赠金才可添加成功</p>
        <el-form :model="addCreditForm" ref="addCreditForm" :inline="true" :rules="rules">
            <el-form-item label="测试环境" prop="adminEnv">
                <el-autocomplete v-model="addCreditForm.adminEnv" :fetch-suggestions="querySearchAsync"
                    placeholder="注：不填默认core环境" @select="handleSelect" clearable></el-autocomplete>
            </el-form-item>
            <div :style="{ whiteSpace: 'pre-line' }"></div>
            <el-form-item label="品牌" prop="brand">
                <el-select v-model="addCreditForm.brand" placeholder="请输入品牌" style="width: 150px">
                    <el-option v-for="brand in brandArr" :key="brand" :lable="brand" :value="brand"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="监管" prop="regulator">
                <el-select v-model="addCreditForm.regulator" placeholder="请输入监管" style="width: 150px">
                    <el-option v-for="regulator in regulatorArr" :key="regulator" :label="regulator"
                        :value="regulator"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="AccountNum" prop="accountNum">
                <el-input v-model="addCreditForm.accountNum" placeholder="请输入account账号"></el-input>
            </el-form-item>
            <el-form-item label="金额(已涵盖币种)" prop="money">
                <el-input v-model="addCreditForm.money" placeholder="请输入赠金金额"></el-input>
            </el-form-item>
        </el-form>
        <el-button type="primary" :loading="isLoading" icon="el-icon-edit-outline"
            @click="addCredit('addCreditForm')">添加赠金</el-button>
        <el-button icon="el-icon-delete" @click="resetForm('addCreditForm')">重置</el-button>
    </div>
</template>
<script>
import { Autocomplete } from 'element-ui';
import envObj from '../../../public/env.json';
import regulatorObj from '../../../public/regulator.json';
export default {
    components: {
        'el-autocomplete': Autocomplete
    },
    data() {
        const brandArr = Object.keys(regulatorObj)
        const envKey = Object.keys(envObj);
        const envArr = envObj[envKey].map(item => ({ value: item }))
        return {
            envArr,
            brandArr,
            isLoading: false,
            regulatorObj,
            regulatorArr: ['VFSC', 'VFSC2', 'ASIC', 'FCA'],
            addCreditForm: {
                adminEnv: '',
                regulator: '',
                accountNum: '',
                brand: 'AU',
                money: ''
            },
            rules: {

                brand: [
                    {
                        required: true,
                        message: 'Please input brand',
                        trigger: 'blur'
                    }
                ],
                regulator: [
                    {
                        required: true,
                        message: 'Please input regulator',
                        trigger: 'blur'
                    }
                ],
                accountNum: [
                    {
                        required: true,
                        message: 'Please input accountNum',
                        trigger: 'change'
                    }
                ],
                money: [
                    {
                        required: true,
                        message: 'Please input money',
                        trigger: 'change'
                    }
                ]
            },
        }
    },
    methods: {
        addCredit(formName) {
            this.isLoading = true;
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    try {
                        const res = await this.$http.post(
                            'api/addCredit',
                            this.addCreditForm
                        )
                        this.result = res.data.data
                        this.$message.success(this.result.result);
                        console.log(this.result)
                        this.isLoading = false;
                    } catch (error) {
                        this.isLoading = false;
                        this.$message.error('API request failed!');
                    }
                } else {
                    console.log('error submit!!');
                    this.isLoading = false;
                    return false;
                }
            });
        },
        querySearchAsync(queryString, cb) {
            var restaurants = this.envArr;
            var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
            cb(results);
        },

        createStateFilter(queryString) {
            return (state) => {
                return (
                    state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
                );
            };
        },

        handleSelect(item) {
            console.log(item);
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        onBrandChange() {
            this.regulatorArr = this.regulatorObj[this.form.brand] || [];
            this.form.regulator = ''; // 清空已选中的型号
        }
    },
    watch: {
        'addCreditForm.brand'(newVal, oldVal) {
            console.log(`品牌从 ${oldVal} 变为 ${newVal}`);
            this.addCreditForm.regulator = regulatorObj[newVal][0];
        }
    }
}
</script>
<style></style>