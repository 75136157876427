<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div>
        <el-tabs v-model="tabName" @tab-click="handleClick">
          <el-tab-pane label="进行中" name="first">
            <search-component
              :key="timer"
              :itemList="searchList"
              :formData="searchData"
            >
            </search-component>
            <el-table
              :data="bugPageListNow.records"
              stripe
              height="550"
              border
              style="width: 96%"
              align="center"
            >
              <el-table-column label="bug编号" width="80px">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span>{{ scope.row.id }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="bug标题" width="">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <el-button type="text" @click="getBugDetail(scope.row)">{{
                      scope.row.title
                    }}</el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="bug状态" width="90px">
                <template slot-scope="scope">
                  <div
                    slot="reference"
                    class="name-wrapper"
                    style="line-height: 20px"
                  >
                    <el-tag
                      size="medium"
                      v-if="scope.row.statusStr === '已解决'"
                      type="success"
                      effect="dark"
                      >{{ scope.row.statusStr }}</el-tag
                    >
                    <el-tag
                      size="medium"
                      v-if="scope.row.statusStr === '已关闭'"
                      type="info"
                      effect="dark"
                      >{{ scope.row.statusStr }}</el-tag
                    >
                    <el-tag
                      size="medium"
                      v-if="
                        scope.row.statusStr === '待解决' ||
                        scope.row.statusStr === '重新打开'
                      "
                      type="warning"
                      effect="dark"
                      >{{ scope.row.statusStr }}</el-tag
                    >
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="bug等级" width="80px">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium">{{ scope.row.levelStr }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="bug类型" width="100px">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium">{{ scope.row.typeStr }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="创建人" width="90px">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium">{{ scope.row.createName }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="处理人" width="90px">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium">{{ scope.row.handleName }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="所属版本" width="80px">
                <template slot-scope="scope">
                  <div slot="version" class="name-wrapper">
                    <span size="medium">{{ scope.row.version }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="所属需求" width="200px">
                <template slot-scope="scope">
                  <div slot="version" class="name-wrapper">
                    <span size="medium">{{ scope.row.demandName }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="发起时间" width="160px">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium">{{ scope.row.createTime }}</span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="结束时间" width="160px">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium">{{ scope.row.updateTime }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="所用时长" width="120px">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium">{{ scope.row.expend }}</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="已关闭" name="second">
            <search-component
              :key="timer"
              :itemList="searchList"
              :formData="searchData"
            >
            </search-component>
            <el-table
              :data="bugPageListHistory.records"
              stripe
              height="550"
              border
              style="width: 96%"
              align="center"
            >
              <el-table-column label="bug编号" width="80px">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span>{{ scope.row.id }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="bug标题" width="">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <el-button type="text" @click="getBugDetail(scope.row)">{{
                      scope.row.title
                    }}</el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="bug状态" width="90px">
                <template slot-scope="scope">
                  <div
                    slot="reference"
                    class="name-wrapper"
                    style="line-height: 20px"
                  >
                    <el-tag
                      size="medium"
                      v-if="scope.row.statusStr === '已解决'"
                      type="success"
                      effect="dark"
                      >{{ scope.row.statusStr }}</el-tag
                    >
                    <el-tag
                      size="medium"
                      v-if="scope.row.statusStr === '已关闭'"
                      type="info"
                      effect="dark"
                      >{{ scope.row.statusStr }}</el-tag
                    >
                    <el-tag
                      size="medium"
                      v-if="
                        scope.row.statusStr === '待解决' ||
                        scope.row.statusStr === '重新打开'
                      "
                      type="warning"
                      effect="dark"
                      >{{ scope.row.statusStr }}</el-tag
                    >
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="bug等级" width="80px">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium">{{ scope.row.levelStr }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="bug类型" width="100px">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium">{{ scope.row.typeStr }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="创建人" width="90px">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium">{{ scope.row.createName }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="处理人" width="90px">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium">{{ scope.row.handleName }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="所属版本" width="80px">
                <template slot-scope="scope">
                  <div slot="version" class="name-wrapper">
                    <span size="medium">{{ scope.row.version }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="所属需求" width="200px">
                <template slot-scope="scope">
                  <div slot="version" class="name-wrapper">
                    <span size="medium">{{ scope.row.demandName }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="发起时间" width="160px">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium">{{ scope.row.createTime }}</span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="结束时间" width="160px">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium">{{ scope.row.updateTime }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="所用时长" width="120px">
                <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium">{{ scope.row.expend }}</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchData.current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="searchData.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
import SearchComponent from '../../common/Serach.vue';

export default {
  components: {
    SearchComponent,
  },
  data() {
    return {
      tabName: 'first',
      renderComponent: true,

      searchList: [
        {
          label: 'bug编号：',
          prop: 'id',
          type: 'input',
          change: this.getEvent,
        },
        {
          label: 'bug标题：',
          prop: 'title',
          type: 'input',
          change: this.getEvent,
        },
        {
          label: 'bug状态：',
          prop: 'status',
          type: 'select',
          multiple: true,
          options: [
            // bug类型
            {
              label: '待解决',
              value: '00',
            },
            {
              label: '已解决',
              value: '01',
            },
            {
              label: '重新打开',
              value: '02',
            },
            {
              label: '已关闭',
              value: '03',
            },
            {
              label: '已驳回',
              value: '04',
            },
          ],
        },
        {
          label: 'bug等级：',
          prop: 'level',
          type: 'select',
          multiple: true,
          options: [
            // bug等级
            {
              label: '紧急',
              value: '00',
            },
            {
              label: '严重',
              value: '01',
            },
            {
              label: '一般',
              value: '02',
            },
          ],
        },
        // { label: '时间：', prop: 'date', type: 'daterange', valueFormat: 'yyyy-MM-dd' },
        {
          type: 'button',
          btnList: [
            {
              type: 'primary',
              label: '查询',
              size: 'mini',
              icon: 'el-icon-search',
              handle: (row) => this.search(),
            },
            {
              type: 'info',
              label: '重置',
              size: 'mini',
              icon: 'el-icon-setting',
              handle: (row) => this.reset(),
            },
          ],
        },
      ],
      searchData: {
        id: '', // 可以赋予初始值
        title: '',
        status: [],
        level: [],
        current: 1,
        size: 10,
        pages: 1,
      },
      timer: '',
      total: 0,
      reqBugForm: {},
      bugPageListNow: {}, // 进行中的bug列表
      bugPageListHistory: {}, // 已关闭的bug列表
    };
  },
  created() {
    this.activePath = window.sessionStorage.getItem('activePath');
    this.activeName = window.sessionStorage.getItem('activeName');
    this.parentName = window.sessionStorage.getItem('parentName');
    // this.getBugPageListNow(this.reqBugForm)
  },
  methods: {
    async getBugPageListNow(data) {
      const { data: res } = await this.$http.post(
        'py/server/project/getBugPageListNow',
        data
      );
      if (!res.success) return this.$message.error(res.msg);
      this.bugPageListNow = res.data;
      this.total = res.data.total;
    },
    async getBugPageListHistory(data) {
      const { data: res } = await this.$http.post(
        'api/server/project/getBugPageListHistory',
        data
      );
      if (!res.success) return this.$message.error(res.msg);
      this.bugPageListHistory = res.data;
      this.total = res.data.total;
    },
    getBugDetail(row) {
      this.$router.push({
        path: '/bugDetail',
        query: {
          id: row.id,
          demandId: row.demandId,
          handleCode: row.handleCode,
        },
      });
    },
    handleSizeChange(newPage) {
      /**
       * @description：用于分页
       */
      this.searchData.size = newPage;
      if (this.tabName === 'second') {
        // this.getBugPageListHistory(this.reqBugForm)
      }
      if (this.tabName === 'first') {
        // this.getBugPageListNow(this.reqBugForm)
      }
    },
    handleCurrentChange(newCurrent) {
      /**
       * @description：用于分页
       */
      this.searchData.current = newCurrent;
      if (this.tabName === 'second') {
        this.getBugPageListHistory(this.reqBugForm);
      }
      if (this.tabName === 'first') {
        this.getBugPageListNow(this.reqBugForm);
      }
    },
    handleClick(tab) {
      this.tabName = tab.name;
      if (this.tabName === 'second') {
        this.getBugPageListHistory(this.reqBugForm);
      }
      if (this.tabName === 'first') {
        this.getBugPageListNow(this.reqBugForm);
      }
    },
    getEvent(res) {
      console.log(this.searchData);
    },
    search() {
      if (this.tabName === 'second') {
        // this.getBugPageListHistory(this.searchData)
      }
      if (this.tabName === 'first') {
        // this.getBugPageListNow(this.searchData)
      }
    },
    reset() {
      this.searchData = {
        id: '', // 可以赋予初始值
        title: '',
        status: [],
        level: [],
        current: 1,
        size: 10,
        pages: 1,
      };
      this.timer = new Date().getTime();
      if (this.tabName === 'second') {
        this.getBugPageListHistory(this.reqBugForm);
      }
      if (this.tabName === 'first') {
        this.getBugPageListNow(this.reqBugForm);
      }
    },
  },
};
</script>
<style lang="less" scoped></style>
