import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './components/Home.vue'
import Login from './components/Login.vue'
import NavShow from './components/NavShow.vue'
import PlatUser from './components/user/PlatUser.vue'
import User from './components/user/User.vue'




// 短信数据文件引入
import Env from './components/Env.vue'
import LtRecord from './components/record/LtRecord.vue'
import Record from './components/record/Record.vue'




// 项目管理文件引入
import AddBug from './components/pm/bug/AddBug.vue'
import TestDemo from './components/pm/bug/BugRichText.vue'
import DemandDetail from './components/pm/demand/DemandDetail.vue'
import DemandPool from './components/pm/demand/DemandManage.vue'
import EidtSchedule from './components/pm/schedule/EidtSchedule.vue'

import BugDetail from './components/pm/bug/BugDetail.vue'
import BugTrack from './components/pm/bug/BugTrack.vue'
import DemandsPool from './components/pm/demand/DemandsPool.vue'
import MyDemands from './components/pm/demand/MyDemands.vue'
// 工作日历
import WorkCalendar from './components/pm/workCalendar/WorkCalendar.vue'

// 导航管理文件引入
import NavAddress from './components/nav/NavAddress.vue'
import NavigationManagement from './components/nav/NavManage.vue'

// 会议管理文件引入
import Mettings from './components/metting/Mettings.vue'
import MyMettings from './components/metting/MyMettings.vue'

// 注册帮助文档
import Register from './components/Register.vue'


// 自动化测试
import ApiManagement from './components/apiAuto/ApiManagement.vue'
import ObjDetail from './components/apiAuto/ObjDetail.vue'
import Supervise from './components/supervise/SuperviseManage.vue'


// crm管理
import EmailCode from './components/crm/EmailCode.vue'
import IssueReports from './components/crm/IssueReports.vue'
import MyJiraBug from './components/crm/JiraBug.vue'
import JiraEnv from './components/crm/JiraEnv.vue'
import JiraIssues from './components/crm/JiraIssues.vue'
import SqlSearch from './components/crm/SqlSearch.vue'
import CpControl from './components/dataManagement/CpControl.vue'
import OneClickRegister from './components/dataManagement/OneClickRegister.vue'

// DataManagement-数据管理
import AdminAudit from './components/dataManagement/AdminAudit.vue'
import GetEmails from './components/dataManagement/EmailCode.vue'
import OneClickDeposit from './components/dataManagement/oneClickDeposit'
import OneClickWithdrawFunds from './components/dataManagement/oneClickWithdrawFunds'
import OpenNewAccount from './components/dataManagement/openAccount.vue'
import addCredit from './components/dataManagement/addCredit.vue'

/*
 * @Description: 自定义指令
 */


Vue.use(VueRouter)

// 解决同一个路由，再次点击报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
}

Vue.config.productionTip = false

const routes = [

  { path: '/', redirect: '/login' },

  { path: '/login', component: Login },
  {path: '/getEmails', component: GetEmails},
  { path: '/navShow', component: NavShow },
  {path: '/register', component: Register},


  {
    path: '/home',
    component: Home,
    redirect: '/jiraEnv',
    children: [
      { path: '/show', component: NavAddress },
      { path: '/user', component: User },
      { path: '/record', component: Record },
      { path: '/platUser', component: PlatUser },
      { path: '/navigationManagement', component: NavigationManagement },
      { path: '/env', component: Env },
      { path: '/navigation', component: NavAddress },
      { path: '/ltRecord', component: LtRecord },
      { path: '/eidtSchedule', component: EidtSchedule },
      { path: '/demands', component: DemandPool },
      { path: '/addBug', component: AddBug },
      { path: '/testDemo', component: TestDemo },
      { path: '/workCalendar', component: WorkCalendar },
      { path: '/mettings', component: Mettings },
      { path: '/myMettings', component: MyMettings },
      { path: '/demandDetail', component: DemandDetail },
      { path: '/bugDetail', component: BugDetail },
      { path: '/supervise', component: Supervise },
      { path: '/apiManagement', component: ApiManagement },
      { path: '/bug', component: BugTrack },
      { path: '/demandsPool', component: DemandsPool },
      { path: '/myDemand', component: MyDemands },
      { path: '/sqlSearch', component: SqlSearch },
      { path: '/jiraEnv', component: JiraEnv },
      {path: '/objDetail', component: ObjDetail},
      {path: '/openNewAccount', component: OpenNewAccount},
      {path: '/addCredit', component: addCredit},
      {path: '/adminAudit', component: AdminAudit},
      { path: '/emailCode', component: EmailCode },
      {path: '/oneClickDeposit', component: OneClickDeposit},
      {path: '/oneClickWithdrawFunds', component: OneClickWithdrawFunds},
      {path: '/myJiraIssues', component: JiraIssues},
      {path: '/issueReports', component: IssueReports},
      {path: '/myJiraBug', component: MyJiraBug},
      {path: '/oneClickRegister', component: OneClickRegister},
      {path: '/cpControl', component: CpControl},

    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/login' || to.path === '/navShow' || to.path === '/face' || to.path === '/register' || to.path === '/getEmails') {
    return next()
  }

  const token = window.sessionStorage.getItem('token')

  if (!token) return next('./login')
  next()
})

export default router
