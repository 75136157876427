<template>
  <div>
    <!--面包屑区域-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="3">
          <el-button type="primary" @click="groupDialogVisible = true"
            >新增分组</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="groupList" style="width: 100%">
        <el-table-column label="序号" width="100">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.sort }}</span>
          </template>
        </el-table-column>
        <el-table-column label="分组名称" width="180">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row.name }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="updateGroupDialogVisible(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="openNavVisible(scope.row.id)"
              >导航管理</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!--新增分组-->
    <div v-if="groupDialogVisible">
      <el-dialog
        title="新增分组"
        :visible.sync="groupDialogVisible"
        width="25%"
        :modal="true"
        :close-on-click-modal="false"
        destroy-on-close
      >
        <!--新增分组主体部分-->
        <el-form :model="groupForm" :rules="groupRules" ref="groupRef">
          <el-form-item
            label="分组名称："
            prop="name"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="groupForm.name"
              placeholder="请输入分组名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="排序："
            prop="sort"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="groupForm.sort"
              placeholder="请输入整数(大的在前)"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="canelGroupDialog">取 消</el-button>

          <el-button type="primary" @click="saveGroup">确 定</el-button>
        </div>
      </el-dialog>
    </div>

    <!--编辑分组-->
    <div v-if="updateGroupVisible">
      <el-dialog
        title="编辑分组"
        :visible.sync="updateGroupVisible"
        width="25%"
        :modal="true"
        :close-on-click-modal="false"
        destroy-on-close
      >
        <!--编辑分组-->
        <el-form :model="editGroupForm" :rules="groupRules" ref="groupRef">
          <el-form-item
            label="分组名称："
            prop="name"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="editGroupForm.name"
              placeholder="请输入分组名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="排序："
            prop="sort"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="editGroupForm.sort"
              placeholder="请输入整数(大的在前)"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="canelGroupDialog">取 消</el-button>
          <el-button type="primary" @click="updateGroup(editGroupForm)"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>

    <!--新增导航窗口-->
    <el-dialog
      title="导航管理"
      :visible.sync="navDialogVisible"
      v-if="navDialogVisible"
      width="45%"
      :modal="true"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <el-button type="primary" @click="addNav" size="small"
        >添加导航</el-button
      >
      <el-table :data="childList" style="width: 100%">
        <el-table-column label="序号" width="80" autocomplete="off">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.sort"
              :id="scope.row.id"
              autocomplete="off"
              size="small"
              placeholder="请输入序号"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="导航名称" width="180" autocomplete="off">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.name"
              :id="scope.row.id"
              autocomplete="off"
              size="small"
              placeholder="请输入导航名称"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="导航地址">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.url"
              :id="scope.row.id"
              autocomplete="off"
              size="small"
              placeholder="请输入导航地址"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.id == 0 || scope.row.id == null"
              size="mini"
              type="primary"
              @click="saveNav(scope.row)"
              >保存</el-button
            >
            <el-button
              v-else
              size="mini"
              type="primary"
              @click="updateNav(scope.row, scope.$index)"
              >保存</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--新增分组主体部分-->

      <div slot="footer" class="dialog-footer">
        <el-button @click="canelNavDialog">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const checkInt = (rule, value, callback) => {
      // 正整数
      const reg = /^[+]{0,1}(\d+)$/g;
      if (value === '') {
        callback(new Error('排序号码不能为空'));
      } else if (!reg.test(value)) {
        callback(new Error('请输入0及0以上的整数'));
      } else {
        callback();
      }
    };
    return {
      // 当前活动页面路径
      atactivePath: '',
      // 定义当前活动导航的名称
      activeName: '',
      // 定义当前活动导航父级导航名称
      parentName: '',
      dialogTableVisible: false,
      groupDialogVisible: false,
      updateGroupVisible: false,

      navDialogVisible: false,
      isBUttonShow: true, // true隐藏， false显示

      isInputShow: true, // true隐藏， false显示
      childList: [], // 分组中的，子导航数据
      groupForm: {
        name: '',
        sort: '',
        id: '',
      },
      editGroupForm: {
        name: '',
        sort: '',
        id: '',
        pid: '',
      },
      navFrom: {
        id: '', // 分组id
      },
      formLabelWidth: '100px',
      groupRules: {
        name: [
          { required: true, message: '请输入分组名称', trigger: 'blur' },
          { min: 2, max: 20, message: '名称不能小于2字符', trigger: 'blur' },
        ],
        sort: [{ required: true, validator: checkInt, trigger: 'blur' }],
      },
      groupList: [],
      navList: [],
    };
  },
  created() {
    this.activePath = window.sessionStorage.getItem('activePath');
    this.activeName = window.sessionStorage.getItem('activeName');
    this.parentName = window.sessionStorage.getItem('parentName');
    this.getGroupList();
  },
  methods: {
    canelGroupDialog() {
      this.groupDialogVisible = false;
      this.updateGroupVisible = false;
      this.groupForm = {};
    },
    canelNavDialog() {
      this.navDialogVisible = false;
      this.getGroupList();
    },

    // 保存分组
    async saveGroup() {
      this.$refs.groupRef.validate(async (valid) => {
        if (!valid) return;
        const { data: res } = await this.$http.post(
          '/py/server/test/data/addNavigation',
          this.groupForm
        );
        if (!res.success) return this.$message.error(res.msg);

        this.groupDialogVisible = false;
        this.$message.success(res.msg);
        this.getGroupList();
        this.groupForm = {};
      });
    },
    // 修改分组
    async updateGroup(groupForm) {
      const { data: res } = await this.$http.post(
        '/py/server/test/data/updateNavigation',
        groupForm
      );

      if (!res.success) return this.$message.error(res.msg);
      this.$message.success(res.msg);
      this.updateGroupVisible = false;
      this.getGroupList();
    },
    // 获取分组信息
    async getGroupList() {
      const { data: res } = await this.$http.post(
        '/py/server/test/data/getNavigationVO'
      );

      this.groupList = res.data;
    },
    // 获取分组内，导航信息
    async getChildList(id) {
      const { data: res } = await this.$http.post(
        '/py/server/test/data/getChildrenNavigationVO',
        { id: id }
      );
      if (res.success) {
        this.childList = res.data;
      } else {
        return this.$message.error(res.msg);
      }
    },

    async saveNav(row) {
      row.id = this.navFrom.id;

      const { data: res } = await this.$http.post(
        '/py/server/test/data/addUrl',
        row
      );

      if (!res.success) return this.$message.error(res.msg);
      this.$message.success(res.msg);
      this.getChildList(row.id);
    },

    async updateNav(row, index) {
      const { data: res } = await this.$http.post(
        '/py/server/test/data/updateNavigation',
        row
      );

      if (!res.success) return this.$message.error(res.msg);

      this.$message.success(res.msg);
    },
    openNavVisible(id) {
      this.getChildList(id);
      console.log(this.childList);
      this.navFrom.id = id; // 分组id
      this.navDialogVisible = true;
      this.navList = this.childList || [];
    },
    updateGroupDialogVisible(row) {
      this.editGroupForm.name = row.name;
      this.editGroupForm.sort = row.sort;
      this.editGroupForm.id = row.id;
      this.editGroupForm.pid = row.pid;
      this.updateGroupVisible = true;
    },

    addNav() {
      let length = 0;

      if (this.childList && this.childList.length > 0) {
        length = this.childList.length;
      }

      this.childList.push({
        pid: parseInt(length),
        sort: '',
        name: '',
        url: '',
        id: '',
      });
    },
  },
};
</script>

<style>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
